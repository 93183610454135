import { useEffect } from "react";
import "./style.less";

const PrivacyPolicyList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="ppsec1 wow fadeIn" />
      <div className="container">
        <div className="bold-text font20 text-center underLineText title">
          PRIVACY POLICY
        </div>
        <div>
          <p className="text80 font14">
            Flexmoney Technologies Private Limited values and appreciates the
            trust the user places in us to protect and store her/his personal
            information and non-personal information (“Information”) shared with
            us while accessing our website at{" "}
            <a
              href={"https://instacred.me"}
              className="text-color cursorPointer"
              target="_blank"
            >
              https://instacred.me
            </a>
            ,{" "}
            <a
              href={"https://flexmoney.in"}
              className="text-color cursorPointer"
              target="_blank"
            >
              https://flexmoney.in
            </a>{" "}
            including any subdomains of these websites (“Flexmoney Website” or
            “Instacred Website” or “Website”).
          </p>
          <br></br>
          <p className="text80 font14 bold-text">
            We would request you to read the following Privacy Policy (“Policy”)
            carefully to learn about our information collection and
            dissemination practices. This Privacy Policy explains how we collect
            and store Information that identifies and relates to users and how
            we use and protect such Information. This Privacy Policy is an
            electronic record and is enforceable against you under law by way of
            your acceptance hereof. This Privacy Policy does not require any
            physical, electronic, or digital signature of the user.
          </p>
          <br></br>
          <p className="text80 font14">
            This document is published in accordance with the provisions of the
            Digital Lending Guidelines 2022 (as regulated by RBI), Digital
            Personal Data Protection Act 2023, Information Technology Act, 2000
            and rules thereof that require publishing the rules and regulations,
            privacy policy and user agreement for access or usage our website or
            the platform accessed through redirection from merchant portal/page
            (hereinafter referred to as{" "}
            <span className="bold-text">“Flexmoney Platform”</span> or
            <span className="bold-text"> “Platform”).</span>
            <br></br>
            <br></br>
            For the purpose of these Privacy Policy, wherever the context so
            requires, “You” (with the term “Your” or “Yourself” being construed
            accordingly) “Customer” or “User/s” shall mean any natural or legal
            person who accesses, performs any financial transaction or registers
            for creation of an account on Flexmoney Platform or avails the
            services of Flexmoney, or its lending or merchant partners, with Us
            as a person or business entity for any business purposes (“Flexmoney
            Account” or “Account”) or surfs the Platform or makes any
            transactions without registering on the Platform. Unless explicitly
            mentioned hereunder, the term “Flexmoney”, “We”, “Us” & “Our” shall
            mean Flexmoney Technologies Private Limited, a company registered
            under the Companies Act, 2013, having its registered address at B-
            404\405, Rustomjee Central Park, Andheri Kurla Road, Andheri East –
            Mumbai 400059, Maharashtra hereinafter.<br></br>
            <br></br>
            "InstaCred" shall refer to the services provided by Flexmoney's
            platform to You on behalf of our lending partners and/or merchants
            (hereinafter referred to as{" "}
            <span className="bold-text">“Business Partners”</span>), including
            but not limited to the activation, utilization and management of the
            digital credit line offered to You by such lending partners, which
            enables You to make successful transactions at merchant’s shop,
            website and mobile based application in online or offline form.
          </p>
          <br></br>
          <section>
            <p className="text80 font14 bold-text">
              1. Need of this Privacy Policy?
            </p>
            <br></br>
            <p className="text80 font14">
              Flexmoney has provided this Policy to ensure that You are aware
              about disclosure of Your Information. This Privacy Policy
              familiarizes you with:
            </p>
            <ul className="text80 font14">
              <li>
                a) The type of data or information that You share with or
                provide to Us, and/or We collect from You.
              </li>
              <li>
                b) The purpose for collection and usage of such data or
                information from You.
              </li>
              <li>
                c) The information security practices and policies of Flexmoney.
              </li>
              <li>
                d) The policy of Flexmoney on disclosing, sharing, or
                transferring Your data or information with/to third parties for
                fulfilment of services as per applicable law.
              </li>
            </ul>
          </section>
          <section>
            <p className="text80 font14 bold-text">
              2. How and to whom this Policy applies?
            </p>
            <br></br>
            <p className="text80 font14">
              By agreeing to this Privacy Policy and/or by clicking on the
              Privacy Policy tick box (wherever provided), You indicate that You
              understand, agree and give Your explicit consent to the terms of
              this Policy. You further agree and consent that such collection,
              use, storage, disclosure and/or transfer of Your Information for
              performance of services, shall not cause any loss or wrongful gain
              to You or any other person related to you.
            </p>
            <br></br>
            <p>
              The terms of this Privacy Policy will be effective upon Your
              access/ registration/ signup/ transaction on or through Our
              Platform and will govern the relationship between You and
              Flexmoney for Your use of the products and services offered by Us
              or Our Business Partners.
            </p>
          </section>
          <br></br>
          <section>
            <p className="text80 font14 bold-text">
              3. What Information do we collect?
            </p>
            <br></br>
            <p>
              The User while using the Flexmoney Platform may share information
              or data with Us or with Our partners, vendors, third parties, such
              information or data collected may consist of user’s personal
              information, and/or non-personal information.
            </p>
            <br></br>
            <p>
              Personal Information for the purposes of this Policy is
              information which may be utilized for uniquely identifying an
              individual and shall include, but not be limited to, Your name,
              parent’s name, address, telephone number, e-mail address or other
              contact information, date of birth, gender, bank account details,
              proof of identity issued by any governmental authority, internet
              protocol address, identification code of the communication device
              which You use to access the Portal or otherwise deal with
              Flexmoney, Information provided by You towards complying with KYC
              (know Your customer) norms, either as part of Our internal
              policies and as may be required under applicable laws, Information
              relating to query for any products and services offered by Us
              including credit services. The information will be used to
              calculate Your credit score and enables Us to offer You digital
              banking, cashbacks/offers, and financial products such as loans,
              details that You have given permission to upload, sync or import
              (such as phone status & identity, location, camera, microphone or
              short messaging system) any information set out in this clause
              pertaining to third parties that is provided to Us, during Your
              use of the Platform. Any other information provided by You during
              Your registration process on the Flexmoney Platform such as bank
              account details, or other linked payment instrument details, pan
              card, Aadhar (masked) details, biometric information. Any detail
              relating to the above points as provided by You or any of the
              information received under the above points by Us for processing,
              storage or processed under lawful contract otherwise.
            </p>
            <br></br>
            <p>
              Non-Personal Information for the purpose of this Policy would not
              assist Flexmoney to identify You personally and shall not be
              limited to Your geographic location, details of Your telecom
              service provider or internet service provider, type of web browser
              (Internet Explorer, Firefox, Opera, Google Chrome etc.), operating
              system, device and the website/platform You last visited before
              visiting Flexmoney Portal, clickstream data relating to Your
              activity on the Flexmoney Portal, including but not limited to the
              duration of Your stay on the Flexmoney Platform, date and time of
              Your access to the Platform.
            </p>
          </section>
          <br></br>
          <section>
            <p className="text80 font14 bold-text">
              4. What are the methods of collecting such information?
            </p>
            <br></br>
            <p>
              Your Information may be collected in various ways including during
              the course of:
            </p>
            <br></br>
            <ul className="text80 font14">
              <li>a) Registering as a User on Our Platform.</li>
              <li>
                b) Availing products and services offered on the Platform or by
                Our Business Partners.
              </li>
              <li>
                c) Doing business, transacting, or attempting to transact on the
                Platform or with the merchant partners.
              </li>
              <li>
                d) Dealing with any of Flexmoney’s partners, vendors, or third
                parties
              </li>
            </ul>
          </section>
          <section>
            <p className="bold-text">
              5. What is the purpose of collecting and using Information?
            </p>
            <br></br>
            <p>
              Flexmoney reserves the right to collect, use, store, process and
              disclose Your Information for any purpose that may be permissible
              as per law and based on the consent obtained from You. Flexmoney
              uses Information that is collected for lawful purposes associated
              with the functions and activities of Flexmoney. Such purposes of
              collecting and using Information{" "}
              <span className="bold-text">(“Purposes”)</span> include but are
              not limited to:
            </p>
            <br></br>
            <ul className="text80 font14">
              <li>
                a) Facilitating Your use of the Platform or other services of
                Flexmoney.
              </li>
              <li>
                b) Responding to Your inquiries or fulfilling Your requests for
                information about the various products and services offered on
                the Platform.
              </li>
              <li>
                c) Providing You with information about the products and
                services available on the Platform and sending You information,
                materials, and offers about products and services of Flexmoney.
              </li>
              <li>
                d) Sending You important information regarding the Platform,
                changes in terms and conditions, user agreements, and policies
                and/or other administrative information.
              </li>
              <li>
                e) Sending You surveys and marketing communications that
                Flexmoney believes may be of interest to You.
              </li>
              <li>
                f) Personalizing Your experience on the Platform by presenting
                advertisements, products and offers tailored to Your
                preferences.
              </li>
              <li>
                g) Helping You address Your problems incurred on the Platform
                including addressing any technical problems.
              </li>
              <li>
                h) If You purchase any product or avail any service from the
                Platform, completing and fulfilling Your purchase. For example,
                having Your payments processed, communicating with You regarding
                Your purchase, and providing You with related customer service;
              </li>
              <li>i) Properly administering the Platform.</li>
              <li>
                j) Conducting internal reviews and data analysis for the
                Platform (e.g., to determine the number of visitors to specific
                pages within the Platform).
              </li>
              <li>
                k) Improving the services, content and advertising on the
                Platform.
              </li>
              <li>
                l) Facilitating various programmes and initiatives launched by
                Flexmoney by itself or through a collaboration with third party
                service providers or Business Partners.
              </li>
              <li>m) Protecting the integrity of the Platform.</li>
              <li>
                n) Responding to legal, judicial, quasi-judicial processes and
                providing information to law enforcement agencies or in
                connection with an investigation on matters related to public
                safety, as permitted by law.
              </li>
              <li>
                o) Conducting analytical studies on various aspects including
                user behaviour, user preferences etc.
              </li>
              <li>
                p) Permitting third parties who may need to contact users who
                have bought products from the Platform to facilitate
                installation, service and any other product related support.
              </li>
              <li>
                q) Implementing information security practices or measures.
              </li>
              <li>
                r) Determining any security breaches, computer contaminant or
                computer virus.
              </li>
              <li>
                s) Investigating, preventing, or taking action regarding illegal
                activities and suspected fraud.
              </li>
              <li>
                t) Undertaking forensics of the concerned computer resource as a
                part of investigation or internal audit.
              </li>
              <li>
                u) Tracing computer resources or any person who may have
                contravened or is suspected of having or being likely to
                contravene, any provision of law including the Information
                Technology Act, 2000 that is likely to have an adverse impact on
                the services provided on any Platform or by Flexmoney.
              </li>
              <li>
                v) Accessing to Your camera, microphone, location or any other
                facility necessary for the purpose of on-boarding/ KYC or any
                other requirements directed by Our Business Partners or as per
                the law.
              </li>
            </ul>
          </section>
          <section>
            <p className="bold-text">
              6. How and where are the Information being stored, processed or
              transferred?
            </p>
            <br></br>
            <p>
              Your Information shall primarily be stored in electronic form,
              however, certain data may also be stored in physical form by Our
              Business Partners (for such physical storage of data, Flexmoney
              shall not be held liable). We store, collect, process and use Your
              Information within the territories of India and the data is stored
              in a secure cloud/servers situated in India. Flexmoney shall keep
              Your Personal Information confidential to the maximum possible
              extent and limited the disclosure of such Personal Information to
              Flexmoney's employees, independent contractors, consultants,
              business associates, service providers and distributors of
              Flexmoney's Service, on a need-to-know basis.
            </p>
            <br></br>
            <p>
              Flexmoney shall make limited disclosure of Personal Information
              for compliance with orders or requests from government
              authorities, law enforcement agencies, regulatory authorities, and
              any agency or process which requires Your Personal Information to
              be shared by Flexmoney as a requirement under law. Flexmoney
              shares Personal Information only for the purposes stated
              hereinabove.
            </p>
            <br></br>
            <p>
              Your Personal Information may also be disclosed on a need to know
              basis;
            </p>
            <br></br>
            <ul className="text80 font14 sqauare-list">
              <li>
                to Flexmoney's business affiliates (including Business Partners)
                where certain content or Service are jointly offered by
                Flexmoney and such business affiliates;
              </li>
              <li>
                to Flexmoney's third party services providers who provide
                services such as contact information verification, credit score
                determination, payment processing at Merchant outlets, customer
                service, data analysis, marketing assistance, infrastructure
                provision, IT services, auditing services and other similar
                services to enable them to assist Flexmoney in providing
                InstaCred services;
              </li>
              <li>
                in the event of a change in control or constitution of Flexmoney
                (including inter alia its merger, amalgamation or acquisition)
                or the transfer of its business or an asset sale, Your Personal
                Information shall be transferred to the counterparty as a
                business asset. Flexmoney assures You that the confidentiality
                of any Personal Information being transferred in such a scenario
                shall be ensured. In any event, You will be given adequate prior
                notice via the Website and You shall have the option to withdraw
                the Personal Information or seek its deletion by Flexmoney prior
                to such an event; and as Flexmoney may believe to be necessary
                or appropriate:
                <ol type="i">
                  <li>under applicable law;</li>
                  <li>to comply with any judicial processes;</li>
                  <li>to respond to requests from government authorities;</li>
                  <li>to enforce Flexmoney's terms and conditions of use;</li>
                  <li>
                    to protect the operations of Flexmoney or its affiliates;
                  </li>
                  <li>
                    to protect Flexmoney's rights, privacy, safety or property,
                    and/or that of Flexmoney's affiliates, You or others;
                  </li>
                  <li>
                    to allow Flexmoney to pursue available remedies or limit the
                    damages that Flexmoney may sustain. This includes disclosing
                    Your Personal Information to other companies and
                    organizations for fraud protection, account verification,
                    and chargeback risk reduction as per the applicable law.
                  </li>
                </ol>
              </li>
            </ul>
          </section>
          <section>
            <p className="bold-text">
              7. How are non-personal information collected and used?
            </p>
            <br></br>
            <p>
              Non-personal information is any information that does not reveal
              Your specific identity, such as, browser information, Internet
              protocol (IP) address, operating system, and platform, information
              collected through cookies, pixel tags and other technologies,
              demographic information, time zone setting, etc. ("Non-Personal
              Information"). As is the case with most websites, the Website
              gathers some Information automatically when You visit the URL of
              the Website and stores it in log files/ cookies. Accordingly, when
              You use the Platform, Flexmoney may collect certain Information
              about Your computer to facilitate, evaluate and verify Your use of
              the Platform. Such Information is generally collected in aggregate
              form, without identifying any User individually.
            </p>
            <br></br>
            <p>
              The Website may also collect certain Non-Personal Information
              through cookies while You access the Website.
            </p>
            <p>
              Cookies are text files which are placed in Your computer to
              identify Your internet browser, store Your preferences, and to
              improve Your experience on the Website. Data in cookies may be
              read to authenticate user sessions or provide Service. The cookies
              do not contain any Personal Information. You may choose to disable
              any cookies by appropriately adjusting Your computer/browser
              settings, however, this may hamper the full functioning of the
              Website. Non-Personal Information may be used and disclosed by
              Flexmoney for any purpose.
            </p>
          </section>
          <br></br>
          <section>
            <p className="bold-text">
              8. Is there any third-party advertisers or links included?
            </p>
            <br></br>
            <p>
              The Website may provide third-party advertisements and links to
              other websites, including products of Our lending partners.
              Flexmoney does not provide any Personal Information to these
              third-party websites or advertisers. The links to other websites
              on the Website are operated by third parties and are not
              controlled by, or affiliated to, or in any way associated with,
              Flexmoney unless explicitly specified on the Website. The
              inclusion or exclusion of any such third-party advertisers does
              not imply any endorsement by Flexmoney of such website, the
              website's provider, or the information on the website. The
              information provided by You to such third-party websites shall be
              governed in accordance with the privacy policies of such websites
              and it is recommended that You review the privacy policy on any
              such websites prior to using or sharing any Personal Information
              on such websites. These third-party websites and advertisers may
              use technology to send the advertisements that appear on the
              Website directly to Your browser. In such cases, the third-party
              websites or advertisers automatically receive Your IP address.
              They may also use cookies and other technologies to measure the
              effectiveness of their advertisements and to personalize
              advertising content. Flexmoney is not liable for any loss, damage,
              claim or expenses suffered by You as a result of You accessing
              these third-party sites and features.
            </p>
          </section>
          <br></br>
          <section>
            <p className="bold-text">9. Do we use cookies?</p>
            <br></br>
            <p>
              We may use cookies for providing speedy responses wherever
              possible to Our Users to ensure the best personalised experience
              of Our Platform.
            </p>
          </section>
          <br></br>
          <section>
            <p className="bold-text">
              10. Are there any Security controls or measures to safeguard
              information?
            </p>
            <br></br>
            <p>
              Flexmoney’s processes and security controls provide an effective
              framework to protect Information. Our Platform is scanned on a
              regular basis for security holes and known vulnerabilities in
              order to make Your visit to our site as safe as possible. Your
              Information is contained behind secured networks and is only
              accessible by a limited number of persons who have special access
              rights to such systems and are required to keep the information
              confidential. We implement a variety of security measures when a
              user places an order, enters, submits, or accesses their
              information to maintain the safety of Your Information.
            </p>
            <br></br>
            <p>
              Flexmoney gives You access to a broad range of Information about
              Your account and Your interactions with Flexmoney for the limited
              purpose of viewing and, in certain cases, updating that
              Information. Such Information includes, up-to-date information
              regarding recent transactions/services utilized by You; Personal
              Information, e-mail notification settings (including alerts,
              recommended for You, and newsletters); recommendations (including
              recent content view history, and favourites); and Your profile
              (including Your Service reviews, requests, and recommendations,
              guides, etc.)
            </p>
            <br></br>
            <p>
              As per the terms and conditions of use, Flexmoney has the right to
              restrict access of any User from using InstaCred, or any Service
              provided by Flexmoney (Restricted User). With respect to a
              Restricted User, Flexmoney will delete any or all Personal
              Information given by the Restricted User, and the terms of this
              Policy will not apply to such Restricted User. If Flexmoney, by
              its sole discretion decides to retain the Personal Information for
              its records, or as required by law, then Flexmoney will be
              responsible to safeguard that Personal Information to such extent
              as required by the applicable law.
            </p>
            <br></br>
            <p>
              While We will endeavour to take all reasonable and appropriate
              steps to keep secure any Information which We hold and prevent
              unauthorized access, You acknowledge that the internet or computer
              networks are not fully secure and that We cannot provide any
              absolute assurance regarding the security of Your Information and
              shall not responsible for any breach of security despite Our best
              efforts. Notwithstanding anything contained in this Policy or
              elsewhere, We shall not be held responsible for any loss, damage
              or misuse of Your Information, if such loss, damage or misuse is
              attributable to a Force Majeure Event (as defined below).
            </p>
            <br></br>
            <p>
              A Force Majeure Event shall mean any event that is beyond the
              reasonable control of Flexmoney and shall include, without
              limitation, sabotage, fire, flood, explosion, acts of God, civil
              commotion, strikes or industrial action of any kind, riots,
              insurrection, war, acts of government, computer hacking, phishing,
              unauthorised access to computer, computer system or computer
              network, computer crashes, breach of security and encryption,
              power or electricity failure or unavailability of adequate power
              or electricity.
            </p>
          </section>
          <br></br>
          <section>
            <p className="bold-text">11. User Discretion</p>
            <br></br>
            <p>
              When You use the Platform, Flexmoney makes good faith efforts to
              provide You with access to Your Information and other relevant
              data, either to correct this data if it is inaccurate, or to
              delete such data at Your request, if it is not otherwise required
              to be retained by law or for legitimate business purposes. Such
              right may be exercised by You by writing to Us at{" "}
              <a
                className="text-color cursorPointer"
                href="mailto:contact@flexmoney.in"
              >
                contact@flexmoney.in
              </a>
              . However, We may retain Your personal data as long as the purpose
              for its usage exists, after which the same may be disposed-off.
              The provisions of various laws may require Your transaction logs,
              personal data or KYC details to be stored for longer periods post
              the deletion of Your account, Flexmoney shall abide by such
              applicable laws. You can always choose not to provide Information,
              even though it might be needed to access/utilize the Platform. In
              such cases, if the Information required is classified as
              mandatory, You will not be able to avail/utilize the service of
              that part or all of the Platform.
            </p>
            <br></br>
            <p>
              Flexmoney may, send direct advertisement mailers to You at the
              address or contact information given by You (if provided by you).
              You have the option to opt-out of this direct mailer by way of
              links provided at the bottom of each mailer. We respect Your
              privacy and if You do not want to receive e- mail or other mail
              from Flexmoney, please adjust Your customer communication
              preferences.
            </p>
          </section>
          <br></br>
          <section>
            <p className="bold-text">12. General Provisions</p>
            <br></br>
            <ul className="text80 font14 circle-list">
              <li>
                By accessing the Website or InstaCred, You have agreed to the
                terms set out in this Policy. This Policy should be at all times
                read along with the General Terms and Conditions. The General
                Terms and Conditions can be accessed{" "}
                <a
                  className="text-color cursorPointer"
                  href={"/terms-and-condition"}
                >
                  here
                </a>
                .
              </li>
              <li>
                Unless stated otherwise, the Policy applies to all Information
                that Flexmoney has about You and Your account, transaction or
                the credit facility availed.
              </li>
              <li>
                Flexmoney may from time to time, make changes to this Policy at
                its sole discretion or on account of changes in law. Unless
                otherwise specified, such revisions shall take effect from the
                day and date they are posted on the Website.
              </li>
              <li>
                Your continued use of the Website, after the revision of the
                Policy constitutes Your acceptance of the revised policy and
                Flexmoney shall be under no obligation to notify You about the
                changes.
              </li>
              <li>
                If You choose to visit the Website, Your visit and any dispute
                over privacy is subject to this Policy and the Website's terms
                and conditions of use, and the applicable law shall be the laws
                of the Republic of India.
              </li>
              <li>
                The competent courts at Mumbai, Maharashtra, India will have
                exclusive jurisdiction for any or all disputes arising out of,
                or in connection with this Policy.
              </li>
            </ul>
          </section>
          <section>
            <p className="bold-text">
              13. Grievance Redressal and Contact details
            </p>
            <br></br>
            <p>
              If You have concerns, questions, complaints or grievances about,
              the Website, or this Policy, please contact Flexmoney at{" "}
              <a
                className="text-color cursorPointer"
                href="mailto:contact@flexmoney.in"
              >
                contact@flexmoney.in
              </a>
            </p>
          </section>
          <br></br>
          <br></br>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicyList;
