import { useEffect } from "react";
import "../PrivacyPolicy/style.less";

const TermsAndConditionList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="ppsec1 wow fadeIn" />
      <div className={"container"}>
        <div className={`bold-text font18 termsHeader text-center title`}>
          GENERAL TERMS & CONDITIONS
        </div>
        <section>
          <p className="bold-text">
            For the purpose of these General Terms and Conditions, wherever the
            context so requires, “You” (with the term “Your” or “Yourself” being
            construed accordingly) “Customer(s)” or “User(s)” shall mean any
            natural or legal person who has accessed, or uses for any financial
            transaction or avails the services of Flexmoney, or it partners with
            us as a person or business entity for any business purposes or surfs
            the Platform or makes any transactions with or without registering
            on the Platform. Unless explicitly mentioned hereunder, the term
            “Flexmoney”, “We”, “Us” & “Our” shall mean Flexmoney Technologies
            Private Limited, a company incorporated under the Companies Act,
            2013, having its registered address at B- 404\405, Rustomjee Central
            Park, Andheri Kurla Road, Andheri East Mumbai -400059, Maharashtra
            hereinafter.
          </p>
          <br></br>
          <p>
            This document is published in accordance with the provisions of the
            Digital Lending Guidelines 2022 (as regulated by RBI), Digital
            Personal Data Protection Act 2023, Information Technology Act, 2000
            and rules thereof that require publishing the rules and regulations,
            privacy policy and user agreement for access or usage our website or
            the platform accessed through redirection from merchant
            portal/page/app/payment link or via InstaCred web page.
          </p>
          <br></br>
          <p>
            The Platform is published and maintained solely by Flexmoney. On the
            Platform, Users will find digital content, including but not limited
            to details about our Products and Services, options to register as a
            customer of Flexmoney, Business Partner and/or any application
            programming interface or domains/interface of our Business Partners
            (lending partner or merchants or vendors or any other entity
            associated with Us) or their subdomains and/or any mobile
            application, which are licensed, and/or purchased, and/or developed,
            and/or owned by Flexmoney. When the User accesses browses or uses
            our Platform, User accepts, without limitation or qualification, the
            terms and conditions set forth herein below. You hereby declare that
            You understand and accept these General Terms and Condition to be
            the legal equivalent of a signed, written contract and to be
            similarly binding by accessing, viewing, or using the content and
            functionality on the Service. You also affirm that You accept these
            General Terms and Condition and agree to be bound by them.{" "}
            <span className="bold-text">
              YOU MUST CEASE USE OF THE SERVICES, VISIT THE WEBSITE OR USE OUR
              PLATFORM IF YOU DISAGREE WITH ANY PORTION OF THESE GENERAL TERMS
              AND CONDITION, AND MAY CONTACT US.
            </span>
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">DEFINITION:</span> All the
            defined and capitalized terms in these General Terms and Condition
            (“T&C”) will have the meaning assigned to them below:
          </p>
          <br></br>
          <ul className="font14">
            <li>
              a. "Lending Partner" shall mean any of Flexmoney’s partner lending
              institutions that underwrite the credit provided via the InstaCred
              service.
            </li>
            <li>
              b. "InstaCred" shall refer to the services provided by Flexmoney's
              platform to You on behalf of our Business Partners, including but
              not limited to the activation, utilization and management of the
              digital credit line offered to You by such lending partners, which
              enables You to make successful transactions at merchant’s shop,
              website and mobile based application in online or offline form.
            </li>
            <li>
              c. "Merchant" shall mean a third-party company, person,
              organisation or entity, selected on the terms identified by
              Flexmoney and the Lending Partner, which sells products and/ or
              services online to You.
            </li>
            <li>
              d. "Platform" shall mean the website of Flexmoney, Instacred, or
              the technology platform build by Flexmoney to provide services to
              its customer and/or any application interface operated or
              controlled by Flexmoney which is accessed by a Customer.
            </li>
            <li>
              e. "Product" shall mean any goods or items offered to sale or sold
              by the Merchant.
            </li>
            <li>
              f. "Services" shall mean the instant credit facility provided by
              Flexmoney’s Business Partners and/or any services availed by you
              through our Platform.
            </li>
          </ul>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">PROPRIETARY RIGHTS:</span>{" "}
            All material contained on the Platform is protected by law. Removing
            or altering the copyright notice on any material on this website and
            Platform is prohibited. Flexmoney also owns a copyright in this
            Platform as a collective work and/or compilation and in the
            selection, coordination, arrangement, organization, and enhancement
            of such content. Any commercial use of this content is prohibited
            without the prior written consent of Flexmoney. Flexmoney owns the
            copyrights, and someone else cannot make a copy or use it without
            the permission of Flexmoney. If any person or a company uses our
            copyrighted material, they shall face legal action as per the law.
            These TnC’s constitute the entire agreement between You and
            Flexmoney concerning Your use of the Services. You acknowledge that,
            in providing You access to the Platform and use of the Services, You
            are legally bound by these General Terms and Condition.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">PRIVACY STATEMENT:</span>{" "}
            Flexmoney has a privacy statement disclosing what information we
            collect about visitors, how we use such information, and the steps
            we take to secure such information.{" "}
            <a href={"/privacy-policy"} className="text-color cursorPointer">
              Click here
            </a>{" "}
            to view our Privacy Policy
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">
              USE OF INFORMATION SHARED BY YOU:
            </span>{" "}
            You hereby agree and irrevocably authorizes that we have the right
            to:
            <br></br>
            <br></br>
            Use for our own purposes, any data or/and information shared by You
            in connection with the Service available on or provided through our
            Platform and/or pass on such information to any other partnered
            companies or selected third parties for the fulfilment of Service.
            Retain all data or/and information shared by You while using the
            Service or to be associated with us on our Platform for the
            exclusive use of Flexmoney. All such data and information shall
            remain in our records and databases as the exclusive property of
            Flexmoney, at all times. By entering Your mobile number on our
            Platform, You give express consent to Flexmoney and its partners,
            vendors and sub-partner, sub vendor to send alerts, promotional SMS
            and promotional calls, irrespective of whether the same is on the do
            not disturb list or any other related data base. You further agree
            that we may share the recording/logs/consent of Yours of the
            aforementioned alert, SMS or call, with concerned agents, partners,
            vendors, and sub-partners and we also have the right to share the
            same with a service provider and/or any statutory/regulatory
            authority if required.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">
              LINKS TO OTHER SERVICES:
            </span>{" "}
            Our Platform may, from time to time, contain links to other internet
            web services for Users to locate any of our partners services,
            information, or services that may be of interest. These other
            internet web services are maintained by organizations over which
            Flexmoney exercises no control. Flexmoney disclaims any
            responsibility for the content, the accuracy of the information,
            and/or the quality of products or services provided by or advertised
            on these third-party web services. Flexmoney does not control,
            endorse, promote, or have any affiliation with any other web service
            unless expressly stated herein. The Business Partner’s website is
            solely controlled and managed by the Business Partners.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">
              DISCLAIMER OF WARRANTY AND LIABILITY:
            </span>{" "}
            Use of this Platform and Services is entirely at Your risk. Neither
            Flexmoney nor its directors, executives or employees are responsible
            for the consequences of reliance on any information contained in or
            submitted to the Platform. These materials are provided without
            warranty, either express or implied. Flexmoney shall not be liable
            for any direct, special, indirect, incidental, consequential, or
            punitive damages, including, without limitation, lost revenues or
            lost profits, which may result from the use of, access to, or
            inability to use these materials.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">
              CORRECTIONS AND CHANGES:
            </span>{" "}
            While we endeavour to keep the Platform up to date, Flexmoney cannot
            assume responsibility for any errors or omissions in the same.
            Flexmoney further does not warrant the accuracy or completeness of
            the information, text, graphics, links, or other items contained on
            the Platform. Flexmoney may change these materials, or the products
            or Services described herein at any time without notice and does not
            commit to updating the information contained herein. We reserve the
            right to terminate Your access to the Services if You violate the
            TnC’s or for any reason.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">INDEMNIFICATION:</span>{" "}
            You agree to defend, indemnify, and hold Flexmoney, its officers
            harmless. All our respective directors, officers, employees,
            representatives, proprietors, partners, shareholders, servants,
            principals, agents, predecessors, successors, assigns, and attorneys
            from and against any claims, proceedings, damages, injuries,
            liabilities, losses, costs, and expenses (including attorney’s fees
            and litigation expenses) relating to or arising from Your use of the
            Services and any breach by You of these TnC’s.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">JURISDICTION:</span> This
            TnC’s shall be governed by and construed following the laws of
            Mumbai, Maharashtra without reference to its choice of law rules and
            nowhere else. This Platform is controlled and operated by Flexmoney
            from its offices in Mumbai, Maharashtra. Flexmoney makes no
            representation that materials in the Service are appropriate or
            available for use in other locations, and access to them from
            territories where their contents are illegal is prohibited. Those
            who choose to access this Service from other locations do so of
            their own volition and are responsible for compliance with
            applicable local laws.
          </p>
        </section>
        <br></br>
        <section>
          <p>
            <span className="bold-text underLineText">BREACH:</span> In the case
            of any violation of these General Terms and Conditions, Flexmoney
            reserves the rights to seek all remedies in law and in equity for
            such violation. If there is a conflict between General Terms and
            Condition and the additional terms hosted by any of our partners,
            clients, vendors, the terms and conditions of our Business
            Partner(s) shall supersede any other terms and conditions on our
            platform. We reserve the right, at Our sole discretion, to change,
            modify, add, or remove portions of these General Terms and Condition
            at any time without any prior written notice to You.
          </p>
        </section>
        <br></br>
        <section>
          <p className="bold-text">
            Specific terms and conditions with respect to certain Services are
            provided below:
          </p>
          <br></br>
          <p className="font14 underLineText">
            PRE-APPROVED/EXISTING TO BANK, PRE-QUALIFIED OR NEW TO BANK SERVICES
          </p>
          <ol className="font14">
            <li>
              The credit line/loans sanctioned at the sole discretion of our
              Lending Partners. The Lending Partner reserves the right to decide
              Your eligibility for the credit sanctioning as per the Lending
              Partner’s internal policies and guidelines.
            </li>
            <li>
              The Services availed by You shall be deemed to be Your
              unconditional acceptance of the T&Cs mentioned hereinabove and You
              shall be bound by the same.
            </li>
            <li>
              The Lending Partner shall disburse the respective transaction
              amount from your credit limit (either directly or through
              respective payment gateways) relating to the successful
              transactions initiated by You through our Platform to the
              respective Merchant’s accounts.
            </li>
            <li>
              The credit line/loan and sanctioning of transaction amount shall
              be within the monetary limits set by the respective Lending
              Partner while granting You the credit line/loan and Lending
              Partner reserves the right to vary monetary limits from time to
              time, as required and may intimate the Customer.
            </li>
            <li>
              <p>
                By availing Services from our Platform, You hereby
                unconditionally agree, confirm, declare and undertake as
                follows:
              </p>
              <ol type="a">
                <li>
                  You shall utilize credit facility provided by Us only for
                  genuine lawful personal needs within the territories of India
                  and not otherwise.
                </li>
                <li>
                  You shall avail credit facility provided by Us as per the
                  applicable interest rate and tenure at the checkout page. The
                  Interest shall be applied to the transaction amount utilized
                  by You on daily diminishing method.
                </li>
                <li>
                  You shall repay the credit amount availed as per the Repayment
                  Terms set by the Lending Partner and accepted by you during
                  the activation process.
                </li>
              </ol>
            </li>
            <li>
              Neither the Lending Partner nor Flexmoney shall be responsible or
              liable for any loss or damage, howsoever caused or suffered by You
              arising out of the transactions with Merchants or the Merchant’s
              failure to provide necessary products/services to You for any
              reason whatsoever.
            </li>
            <li>
              You are responsible for maintaining the secrecy of Your passwords,
              PIN, login and account information relating to the services
              availed from our platform. You will be responsible for the use of
              the our Service even if any other person has access to or is using
              Your password, PIN and login information (with or without Your
              permission) and such transaction shall be binding on You.
            </li>
            <li>
              The credit facility transaction once confirmed and processed by
              You cannot be cancelled. The applicable interest and tenure
              confirmed at the time of availing the credit facility cannot be
              changed.
            </li>
            <li>
              The credit amount outstanding can be pre-closed at any time as per
              the terms agreed with the Lending Partners.
            </li>
            <li>
              In the event of You default in repaying the credit amount
              outstanding together with applicable interest, charges, taxes and
              costs and/or in adhering to the T&Cs contained herein,
              <ol type="a">
                <li>
                  You shall be liable to pay additional/penal interest as
                  intimated to You by the Lending Partner.
                </li>
                <li>
                  The Lending Partner shall be entitled to report Your name to
                  CIBIL/RBI or any other statutory, regulatory or rating
                  agencies.
                </li>
                <li>
                  The Lending Partner shall be entitled to initiate such other
                  legally permissible measures against You for recovering the
                  amounts outstanding under the credit line/loan availed,
                  including by engaging the services of recovery agents, in
                  terms of the Lendin Patner's Loan/Debt Collection Policy.
                </li>
                <li>
                  The Lending Partner shall be entitled to pre-close the credit
                  facility at its discretion.
                </li>
              </ol>
            </li>
            <li>
              The Lending Partner reserves the right at any time, without
              previous notice, to add, alter, modify, change or vary all or any
              of its T&Cs which you have agreed in process of availing the
              credit facility. All disputes, if any, arising out of or in
              connection with or otherwise relating hereto shall be subject to
              the exclusive jurisdiction of the competent Courts/Tribunals in
              India only.
            </li>
            <li>
              You agree and declare that the Lending Partner shall have an
              unqualified right to assign the credit availed by You to any other
              Bank/Financial Institution/NBFC of Lending Partner's choice, for
              which purpose no further consent or concurrence shall be required
              from Your part.
            </li>
          </ol>
        </section>
        <section>
          <p className="font14 underLineText">No Cost EMI Services</p>
          <ol className="font14">
            <li>
              The No-Cost EMI facility is being offered to the customers who
              make a purchase transaction on participating merchants’ websites,
              mobile application/ mobile site thereof (collectively,
              "Participating Merchant") using Cardless EMI powered by InstaCred
              through its Lending Partner, providing an EMI facility to its
              pre-approved customers.
            </li>
            <li>
              I understand that under this offer, I will get an upfront discount
              equivalent to the EMI interest cost charged by the bank, making it
              effectively No Cost EMI.
            </li>
            <li>
              Applicable taxes on Interest and any other charges will be levied
              by the Lending Partner.
            </li>
            <li>
              The No-Cost EMI facility is being offered by Flexmoney and/or
              Merchants and brands (as the case may be) and does not amount to
              EMI/interest waiver extended by the bank. No Cost EMI discount is
              made available on select products or services, as determined from
              time to time by such sellers.
            </li>
            <li>
              The No-Cost EMI payment option can be availed using the Cardless
              EMI / Debit Card EMI powered by Flexmoney/InstaCred (facility of
              Flexmoney’s Lending Partner providing an EMI facility to its
              preapproved users) on Participating Merchant.
            </li>
            <li>
              No Cost EMI is not available under this program if purchases are
              made using other payment methods.
            </li>
            <li>
              The Lending Partners issuing the Cardless EMI / Debit Card EMI
              facility reserve the right to charge Processing Fees and/or Goods
              and Services Tax (GST) or other applicable taxes on the purchase
              transactions undertaken on EMI which will be over and above the
              product price. This processing fee will be informed to the
              customer at the time of the transaction.
            </li>
            <li>
              No Cost EMI discount will be calculated only on the eligible items
              in the purchase cart as the case may be.
            </li>
            <li>
              The customers may avail the No Cost EMI facility, provided that
              <ul>
                <li>
                  a. the order is not cancelled by the customer or the
                  participating merchant; or
                </li>
                <li>
                  b. the product is not returned/exchanged by the customer.
                </li>
              </ul>
            </li>
            <li>
              I understand and confirm that I will need to pay applicable EMI
              and interest on the total value of the order at the time of
              payment to the bank in accordance with the terms applicable. The
              interest cost charged by the bank overtime is being given back to
              me upfront at the time of this purchase.
            </li>
            <li>
              Any returns/cancellations will be as per the Merchant's
              return/cancellation policy. In case of any kind of return/refund
              in an EMI transaction, interest already billed in a particular
              transaction will not be refundable under any circumstances.
            </li>
            <li>
              In the case of a refund on the original transaction, the No Cost
              EMI Discount received by the customer on the transaction will be
              recovered on a pro-rata basis and the amount post adjustment of
              this discount recovery will be passed back to the customer through
              Flexmoney’s Lending Partner.
            </li>
            <li>
              The EMI facility is made available to the customers by and at the
              sole discretion of the Lender(s) issuing the credit facility to
              the customer. Flexmoney, InstaCred, Participating Merchant, and/or
              the brand will not be liable for any claims on account of
              availability or non-availability of EMI facility. Customers having
              the credit facility may be required to enter into separate
              agreements or arrangements (by whatever name called) with their
              Lender to avail / use the EMI facility.
            </li>
            <li>
              Flexmoney, InstaCred, Participating Merchant, Brand, or the Lender
              reserve the right to stop the No Cost EMI option at any time
              without prior notice and without any liability.
            </li>
            <li>
              These terms and conditions only pertain to the No Cost EMI
              Discount provided to the customer and do not override any other
              terms, conditions, or agreement as may be applicable.
            </li>
            <li>
              If You avail any offers, discounts, cashbacks, coupons, etc.
              (“Offers”) provided by Us or Our Lending Partners or Merchants,
              You are agreeing to comply with and accept the terms and
              conditions provided in respect of such Offers.
            </li>
            <li>
              Further, You understand that all terms and conditions provided
              with respect to any Offer shall form part of and shall be read in
              conjunction with these T&C’s. You understand and acknowledges that
              the Offers cannot be exchanged for its value in cash, either in
              full or in part. You further acknowledge that the value stored in
              the Cashback cannot transferred in any manner whatsoever.
            </li>
          </ol>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditionList;
